<template>
  <div class="ant-card" id="course_category">
    <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
      <div class="ant-card-body">
        <div class="head-box">
          <div class="table-operator">
            <span class="item">
              <a-button :disabled="isDisDownTemplate" class="margin_right10" type="primary"
                @click="downloadClick">下载模版</a-button>
            </span>
            <span class="item">
              <a-button class="margin_right10" type="primary" @click="visibleShow = true">添加查询任务</a-button>
            </span>
          </div>
        </div>
        <div class="contant-box">
          <p class="title">导出报表表头展示</p>
          <div class="tabShow">
            <table border="1">
              <tr>
                <th>订单归属</th>
                <th>用户手机号</th>
                <th>商品名称</th>
                <th>订单金额</th>
                <th>支付时间</th>
                <th>订单来源</th>
              </tr>
            </table>
          </div>

          <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.TaskName" :loading="tableLoading" :pagination="false">
            <span slot="Status" slot-scope="text, record">
              <span>{{ record.Status == 0 ? '未开始' : record.Status == 1 ? '进行中' : record.Status == 2 ? '已完成' : '--'
              }}</span>
            </span>
            <span slot="action" slot-scope="text, record">
              <a @click="isDownloadClick(record)" v-if="record.Status == 2">下载</a>
              <a v-else>--</a>
            </span>
          </a-table>
          <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading" />
        </div>
      </div>
    </a-spin>
    <router-view />
    <a-modal v-model="visibleShow" title="添加" style="height: 500px">
      <a-form-model v-if="visibleShow" ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item ref="cycle" label="查询周期" prop="cycle">
          <a-radio-group v-model="form.cycle" @change="cycleChange" name="radioGroup">
            <a-radio :value="7">7天</a-radio>
            <a-radio :value="15">15天</a-radio>
            <a-radio :value="30">30天</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item ref="order" label="查询信息上传" prop="order">
          <OrderformList @errOrderFn="errOrderFn" @spinningFn="spinningFn" :urlList="adminUrlLink"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import { performaceList } from "@/request/api/reportFormManage";
import OrderformList from "@/components/file/OrderImport2.vue";
export default {
  components: { MyPagination, OrderformList },
  created () {
    this.getTagList();
  },

  data () {
    return {
      visibleShow: false,
      form: {
        cycle: 7,
        order: ''
      },
      rules: {
        cycle: [{ required: true, message: "请选择查询周期", trigger: "change" }],
        order: [{ required: true }],
      },
      spinning: false, // 全局loading
      tableLoading: false,
      columns: [
        {
          title: "报表名称",
          dataIndex: "TaskName",
          key: "TaskName",
        },
        {
          title: "系统生成时间",
          dataIndex: "FinishTime",
          key: "FinishTime",
        },
        {
          title: "进度",
          dataIndex: "Status",
          key: "Status",
          scopedSlots: { customRender: "Status" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "130px",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableList: [],
      // 展示表头
      columnsShow: [
        {
          title: "报表名称",
          dataIndex: "userName",
          key: "userName",
        },
        {
          title: "系统生成时间",
          dataIndex: "nickName",
          key: "nickName",
          scopedSlots: { customRender: "nickName" },
        },
        {
          title: "进度",
          dataIndex: "vipStatus",
          key: "vipStatus",
          scopedSlots: { customRender: "vipStatus" },
        },
      ],
      isDisDownTemplate: false,
      adminUrlLink: '/adminv2/statistics/performance?days=7',
      pageNo: 1, // 当前页
      pageSize: 20, // 每页条数
      count: 0,// 列表的总条数
    };
  },

  watch: {
    $route (to) {
      if (to.path === "/reportFormManage/PerformanceIndax") {
        this.getTagList();
      }
    },
  },

  methods: {
    // 分页功能切换的回调
    showSizeChangeFn (current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.getTagList();
    },

    cycleChange (val) {
      this.adminUrlLink = `/adminv2/statistics/performance?days=${val.target.value}`
    },
    // 订单导入中的
    spinningFn (bool) {
      this.tip = "订单导入中";
      this.spinning = bool;
    },
    // 导入订单返回的回调
    errOrderFn (data) {
      this.visibleShow = false
      this.getTagList()
    },
    // 下载模版
    downloadClick () {
      this.isDisDownTemplate = true;
      setTimeout(() => {
        this.isDisDownTemplate = false;
      }, 3000);
      window.location.href = 'https://pre-kodo.shenyiedu.com/excel/performance.xlsx';
    },

    // 列表数据
    getTagList () {
      performaceList({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 200) {
          this.tableList = res.data.data;
          this.count = res.data.count;
          this.spinning = false;
        } else {
          this.spinning = false;
        }
      });
    },

    // 下载
    isDownloadClick (record) {
      window.location.href = record.FileUrl
    },

    // 回到顶部的函数
    targetFn () {
      return document.querySelector("#course");
    },
  }
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-footer{
  display: none;
}
table {
  width: 100%;
  text-align: center;

  th {
    padding: 10px;
  }
}

.contentRender {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  li {
    text-align: center;
    list-style: none;
  }

  .tables {
    width: 50%;

    .items {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 10px;
      background: rgba(80, 207, 248, 0.2);

      >.title {
        width: 30%;
        font-size: 13px;
        text-align: right;
        font-weight: 500;
      }

      .substance {
        width: 70%;
        font-size: 13px;
        text-align: left;
        font-weight: 500;
      }
    }
  }

  .chart {
    width: 50%;

    .chartCom {
      width: 100%;
      height: 320px;
      margin-top: -10px;
    }

    .chartTitle {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.item {
  margin-right: 20px;
  margin-bottom: 10px;
}

#course_category {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

.head-box {
  display: flex;
  margin-bottom: 5px;
}

.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.btn-edit {
  margin-right: 10px;
}

.table-operator {
  display: flex;
  flex-wrap: wrap;
}

/deep/.vue-treeselect__control {
  width: 210px;
}

/deep/ .ant-input {
  min-height: 36px !important;
}

/deep/ .ant-select-selection--single {
  min-height: 36px !important;
}

/deep/.ant-select-selection__rendered {
  line-height: 35px;
}
</style>
